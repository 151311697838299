export default {
    name: 'evento',
    component: () => import('@/modules/evento/layouts/index.vue'),
    children: [
        {
            path: '',
            name: 'NoSelectedEventoComponent',
            component: () => import('@/modules/evento/page/no-selected.vue')
        },
        {
            path: ':id',
            name: 'eventView',
            component: () => import('@/modules/evento/page/evento.vue'),
            props: (route) => {
                return { id: route.params.id }
            }

        }
    ]
}