export default {
    name: 'inscripcion',
    component: () => import('@/modules/inscripcion/layouts/index.vue'),
    children: [
        {
            path: '',
            name: 'noselected',
            component: () => import('@/components/eventos/allEvents.vue')
        },
        {
            path: ':slug',
            name: 'InscripcionView',
            component: () => import('@/modules/inscripcion/page/inscripcion.vue'),
            props: (route) => {
                return { slug: route.params.slug }
            }

        }
    ]
}