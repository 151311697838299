export default () => ({
    atletas: [],
    config: {},
    evento: {},
    eventos: [],
    eventosactuales: [],
    categorias: [],
    correo: {},
    tasa: {},
    servicios: [],
    sliders: []
})