import eventosApi from "@/api/eventosApi"

export const EventoSeleccionado = async ({commit}, datos) => {
    commit('EventoSeleccionado', datos)
}

export const setEventos = async ({commit}) => {

    if(localStorage.getItem('eventos')){
        if (tiempo() >= (1 * 60 * 60 * 1000)) { // un dia en ms
            localStorage.clear()
            const resp = await eventosApi.get('/eventos')
            if(resp.data.error){ return resp.data }
            commit('setEventos', resp.data)
            localStorage.setItem('fecha', new Date())
            localStorage.setItem('eventos', JSON.stringify(resp.data))
            return resp.data
        } else {
            commit('setEventos', JSON.parse(localStorage.getItem('eventos')))
            return localStorage.getItem('eventos')
        }
    }else{
        const resp = await eventosApi.get('/eventos')
        if(resp.data.error){ return resp.data }
        commit('setEventos', resp.data)
        localStorage.setItem('fecha', new Date())
        localStorage.setItem('eventos', JSON.stringify(resp.data))
        return resp.data
    }

    /*const resp = await eventosApi.get('/eventos')
    if(resp.data){
        if(resp.data.error){
            return resp.data
        }
        commit('setEventos', resp.data)
        return resp
    }*/
}
export const pCarreraSlug = async ({commit}, data) => {
    commit('postCarreraSlug', data)
    return data
}

export const setEventoSlug = async ({commit}, datos) =>{


    if(localStorage.getItem('EventoSeleccionado')){
        let comprobar = JSON.stringify(localStorage.getItem('EventoSeleccionado'))
        if(comprobar.slug != datos){
            const resp = await eventosApi.get(`/evento/${datos}/inscripcion`)
            if(resp.data.error){ return resp.data }
            commit('EventoSeleccionado', resp.data.evento)
            commit('postEvento',         resp.data.evento.post  )
            localStorage.setItem('fecha', new Date())
            localStorage.setItem('EventoSeleccionado', JSON.stringify(resp.data.evento))
            localStorage.setItem('postEvento',         JSON.stringify(resp.data.evento.post))
            return resp.data
        }else if (tiempo() >= (1 * 60 * 60 * 1000)) { // un dia en ms
            localStorage.clear()
            const resp = await eventosApi.get(`/evento/${datos}/inscripcion`)
            if(resp.data.error){ return resp.data }
            commit('EventoSeleccionado', resp.data.evento)
            commit('postEvento',         resp.data.evento.post  )
            localStorage.setItem('fecha', new Date())
            localStorage.setItem('EventoSeleccionado', JSON.stringify(resp.data.evento))
            localStorage.setItem('postEvento',         JSON.stringify(resp.data.evento.post))
            return resp.data
        } else {
            commit('EventoSeleccionado', JSON.parse(localStorage.getItem('EventoSeleccionado')))
            commit('postEvento',         JSON.parse(localStorage.getItem('postEvento')))
            return localStorage.getItem('EventoSeleccionado')
        }
    }else{
        const resp = await eventosApi.get(`/evento/${datos}/inscripcion`)
        if(resp.data.error){ return resp.data }
        commit('EventoSeleccionado', resp.data.evento)
        commit('postEvento',         resp.data.evento.post  )
        localStorage.setItem('fecha', new Date())
        localStorage.setItem('EventoSeleccionado', JSON.stringify(resp.data.evento))
        localStorage.setItem('postEvento',         JSON.stringify(resp.data.evento.post))
        return resp.data
    }


    /*const resp = await eventosApi.get(`/evento/${datos}/inscripcion`)
    if(resp.data){
        if(resp.data.error){
            return resp.data
        }
        commit('EventoSeleccionado', resp.data.evento)
        commit('postEvento',         resp.data.post  )
        return resp.data
    }*/
}
export const postCarreraSlug = async ({commit}, slug) => {

    if(localStorage.getItem('postCarreraSlug')){
        let comprobar = JSON.parse(localStorage.getItem('postCarreraSlug'))
        if(comprobar.slug != slug){
            const resp = await eventosApi.get(`/post/${slug}/informacion`)
            if(resp.data.error){ return resp.data }
            commit('postCarreraSlug', resp.data)
            localStorage.setItem('fecha', new Date())
            localStorage.setItem('postCarreraSlug', JSON.stringify(resp.data))
            return resp.data
        }else if (tiempo() >= (1 * 60 * 60 * 1000)) { // un dia en ms
            localStorage.clear()
            const resp = await eventosApi.get(`/post/${slug}/informacion`)
            if(resp.data.error){ return resp.data }
            commit('postCarreraSlug', resp.data)
            localStorage.setItem('fecha', new Date())
            localStorage.setItem('postCarreraSlug', JSON.stringify(resp.data))
            return resp.data
        } else {
            commit('postCarreraSlug', JSON.parse(localStorage.getItem('postCarreraSlug')))
            return localStorage.getItem('postCarreraSlug')
        }
    }else{
        const resp = await eventosApi.get(`/post/${slug}/informacion`)
        if(resp.data.error){ return resp.data }
        commit('postCarreraSlug', resp.data)
        localStorage.setItem('fecha', new Date())
        localStorage.setItem('postCarreraSlug', JSON.stringify(resp.data))
        return resp.data
    }
} 


export const setEventosPorFecha = async ({commit}) => {

    if(localStorage.getItem('setEventosPorFecha')){
        if (tiempo() >= (1 * 60 * 60 * 1000)) { // un dia en ms
            localStorage.clear()
            const resp = await eventosApi.get('/eventos-por-fecha')
            let argumentos = resp.data.datos
            let years = resp.data.years
            let datos = []
            for (let y = 0; y < years.length; y++) {
                for (let i = 0; i < argumentos.length; i++) {
                    if(argumentos[i][years[y]]){
                        if(argumentos[i][years[y]].length > 0 ){
                            datos.push(argumentos[i][years[y]])
                        }
                    }
                }
            }
            if(resp) {
                if(resp.data){
                    if(resp.data.error){
                        return resp.data
                    }
                    localStorage.setItem('fecha', new Date())
                    localStorage.setItem('setEventosPorFecha', JSON.stringify([years, datos]))
                    commit('setEventosPorFecha', [years, datos])
                    return [years, datos]
                }
            }
        } else {
            commit('setEventosPorFecha', JSON.parse(localStorage.getItem('setEventosPorFecha')))
            return JSON.parse(localStorage.getItem('setEventosPorFecha'))
        }
    }else{
        
        const resp = await eventosApi.get('/eventos-por-fecha')
        let argumentos = resp.data.datos
        let years = resp.data.years
        let datos = []
        for (let y = 0; y < years.length; y++) {
            for (let i = 0; i < argumentos.length; i++) {
                if(argumentos[i][years[y]]){
                    if(argumentos[i][years[y]].length > 0 ){
                        datos.push(argumentos[i][years[y]])
                    }
                }
            }
        }
        if(resp) {
            if(resp.data){
                if(resp.data.error){
                    return resp.data
                }
                localStorage.setItem('fecha', new Date())
                localStorage.setItem('setEventosPorFecha', JSON.stringify([years, datos]))
                commit('setEventosPorFecha', [years, datos])
                return [years, datos]
            }
        }

    }

}



export const setAtleta = async ({commit}, data) => {
    const resp = await eventosApi.post(`/register/registrar`, data)
    if(resp){
        if(resp.data.error){
            return resp.data
        }
        commit('setAtleta', data)
        return resp.data
    }
}

export const postAtleta = async ({commit}, datos) => {
    const resp = await eventosApi.post(`/register/consulta`, datos)
    if(resp){
        if(resp.data.error){
            return resp.data
        }
        commit('postAtleta', resp.data)
        return resp.data
    }
} 


/************************************** 
 * 
 *  BLOG
 * 
*/


export const BlogSeleccionado = async ({commit}, datos) => {
    commit('BlogSeleccionado', datos)
    return datos
}
export const setBlog = async ({commit}, data) => {
    commit('postBlogSlug', data)
    return data
}
export const setBlogs = async ({commit}) => {
    console.log('1----- ')
    if(localStorage.getItem('blogs')){
        console.log('2----- ')
        if (tiempo() >= (1 * 60 * 60 * 1000)) { // un dia en ms
            console.log('3----- ')
            localStorage.clear()
            const resp = await eventosApi.get('/blogs')
            commit('setBlogs', resp.data.blogs)
            if(resp.data.error){ return resp.data }
            localStorage.setItem('fecha', new Date())
            localStorage.setItem('blogs', JSON.stringify(resp.data.blogs))
            return resp.data.blogs
        } else {
            commit('setBlogs', JSON.parse(localStorage.getItem('blogs')))
            return JSON.parse(localStorage.getItem('blogs'))
        }
    }else{
        console.log('4----- ')
        const resp = await eventosApi.get('/blogs')
        commit('setBlogs', resp.data.blogs)
        if(resp.data.error){ return resp.data }
        localStorage.setItem('fecha', new Date())
        localStorage.setItem('blogs', JSON.stringify(resp.data.blogs))
        console.log(resp.data.blogs)
        return resp.data.blogs
    }

    /*const resp = await eventosApi.get('/blogs')
    if(resp.data){
        if(resp.data.error){
            return resp.data
        }
        commit('setBlogs', resp.data.blogs)
        return resp.data.blogs
    }*/
}


export const setBlogSlug = async ({commit}, datos) =>{

    if(localStorage.getItem('BlogSeleccionado')){

        let comprobar = JSON.parse(localStorage.getItem('BlogSeleccionado'))
        if(comprobar.slug != datos){
            const resp = await eventosApi.get(`/blog/${datos}/inscripcion`)
            commit('BlogSeleccionado', resp.data.evento)
            commit('postBlog',         resp.data.post)
            if(resp.data.error){ return resp.data }
            localStorage.setItem('fecha', new Date())
            localStorage.setItem('BlogSeleccionado', JSON.stringify(resp.data.evento))
            localStorage.setItem('postBlog',         JSON.stringify(resp.data.post))
            return resp.data
        }else if (tiempo() >= (1 * 60 * 60 * 1000)) { // un dia en ms
            localStorage.clear()
            const resp = await eventosApi.get(`/blog/${datos}/inscripcion`)
            commit('BlogSeleccionado', resp.data.evento)
            commit('postBlog',         resp.data.post)
            if(resp.data.error){ return resp.data }
            localStorage.setItem('fecha', new Date())
            localStorage.setItem('BlogSeleccionado', JSON.stringify(resp.data.evento))
            localStorage.setItem('postBlog',         JSON.stringify(resp.data.post))
            return resp.data.blogs
        } else {
            commit('BlogSeleccionado', JSON.parse(localStorage.getItem('BlogSeleccionado')))
            commit('postBlog',         JSON.parse(localStorage.getItem('postBlog')))
            const res = [ JSON.parse(localStorage.getItem('BlogSeleccionado'), JSON.parse(localStorage.getItem('postBlog')))]
            return res
        }
    }else{
        const resp = await eventosApi.get(`/blog/${datos}/inscripcion`)
        commit('BlogSeleccionado', resp.data.evento)
        commit('postBlog',         resp.data.post)
        if(resp.data.error){ return resp.data }
        localStorage.setItem('fecha', new Date())
        localStorage.setItem('BlogSeleccionado', JSON.stringify(resp.data.evento))
        localStorage.setItem('postBlog',         JSON.stringify(resp.data.post))
        return resp.data
    }


    /*const resp = await eventosApi.get(`/blog/${datos}/inscripcion`)
    if(resp.data){
        if(resp.data.error){
            return resp.data
        }
        commit('BlogSeleccionado', resp.data.evento)
        commit('postBlog',         resp.data.post  )
    }
    return resp.data*/
}
export const postBlogSlug = async ({commit}, slug) => {

    if(localStorage.getItem('postBlogSlug')){
        let comprobar = JSON.parse(localStorage.getItem('postBlogSlug'))
        if(comprobar.slug != slug){
            const resp = await eventosApi.get(`/post/${slug}/informacion`)
            commit('postBlogSlug', resp.data)
            if(resp.data.error){ return resp.data }
            localStorage.setItem('fecha', new Date())
            localStorage.setItem('postBlogSlug', JSON.stringify(resp.data))
            return resp.data
        }else if (tiempo() >= (1 * 60 * 60 * 1000)) { // un dia en ms
            localStorage.clear()
            const resp = await eventosApi.get(`/post/${slug}/informacion`)
            commit('postBlogSlug', resp.data)
            if(resp.data.error){ return resp.data }
            localStorage.setItem('fecha', new Date())
            localStorage.setItem('postBlogSlug', JSON.stringify(resp.data))
            return resp.data.blogs
        } else {
            commit('postBlogSlug', JSON.parse(localStorage.getItem('postBlogSlug')))
            return JSON.parse(localStorage.getItem('postBlogSlug'))
        }
    }else{
        const resp = await eventosApi.get(`/post/${slug}/informacion`)
        commit('postBlogSlug', resp.data)
        if(resp.data.error){ return resp.data }
        localStorage.setItem('fecha', new Date())
        localStorage.setItem('postBlogSlug', JSON.stringify(resp.data))
        return resp.data
    }


    /*const resp = await eventosApi.get(`/post/${slug}/informacion`)
    if(resp){
        if(resp.data){
            if(resp.data.error){
                return resp.data
            }
            commit('postBlogSlug', resp.data)
            return resp.data
        }
    }*/
} 


export const setBlogsPorFecha = async ({commit}) => {

    if(localStorage.getItem('setBlogsPorFecha')){
        if (tiempo() >= (1 * 60 * 60 * 1000)) { // un dia en ms
            localStorage.clear()
            const resp = await eventosApi.get('/blogs-por-fecha')
            let argumentos = resp.data.datos
            let years = resp.data.years
            let datos = []
            for (let y = 0; y < years.length; y++) {
                for (let i = 0; i < argumentos.length; i++) {
                    if(argumentos[i][years[y]]){
                        if(argumentos[i][years[y]].length > 0 ){
                            datos.push(argumentos[i][years[y]])
                        }
                    }
                }
            }
            if(resp) {
                if(resp.data){
                    if(resp.data.error){
                        return resp.data
                    }
                    localStorage.setItem('fecha', new Date())
                    localStorage.setItem('setBlogsPorFecha', JSON.stringify([years, datos]))
                    commit('setBlogsPorFecha', [years, datos])
                    return [years, datos]
                }
            }
        } else {
            commit('setBlogsPorFecha', JSON.parse(localStorage.getItem('setBlogsPorFecha')))
            return  JSON.parse(localStorage.getItem('setBlogsPorFecha'))
        }
    }else{
        const resp = await eventosApi.get('/blogs-por-fecha')
        let argumentos = resp.data.datos
        let years = resp.data.years
        let datos = []
        for (let y = 0; y < years.length; y++) {
            for (let i = 0; i < argumentos.length; i++) {
                if(argumentos[i][years[y]]){
                    if(argumentos[i][years[y]].length > 0 ){
                        datos.push(argumentos[i][years[y]])
                    }
                }
            }
        }
        if(resp) {
            if(resp.data){
                if(resp.data.error){
                    return resp.data
                }
                localStorage.setItem('fecha', new Date())
                localStorage.setItem('setBlogsPorFecha', JSON.stringify([years, datos]))
                commit('setBlogsPorFecha', [years, datos])
                return [years, datos]
            }
        }
    }




    /*const resp = await eventosApi.get('/blogs-por-fecha')
    var argumentos = resp.data.datos
    var years = resp.data.years
    var datos = []
    for (let y = 0; y < years.length; y++) {
        for (let i = 0; i < argumentos.length; i++) {
            if(argumentos[i][years[y]]){
                if(argumentos[i][years[y]].length > 0 ){
                    datos.push(argumentos[i][years[y]])
                }
            }
        }
    }
    if(resp) {
        if(resp.data){
            if(resp.data.error){
                return resp.data
            }
            commit('setBlogsPorFecha', [years, datos])
            return [years, datos]
        }
    }*/



}





/***********
 * funciones
 ***********/
/// CALCULAMOS TIEMPO

function tiempo(){
    const fecha = localStorage.getItem('fecha')
    const fecha2  = new Date()
    return fecha2 - fecha
}
