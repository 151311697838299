export const correos = (state, datos) => {
    state.correo = datos
}
export const setEventos = (state, datos) => {
    state.eventos = datos
}

export const EventoSeleccionado = (state, datos) => {
    state.eventSelect = datos
    state.camisas = datos.camisas
    state.categorias = datos.cat
    if(localStorage.getItem('eventSelect') != ''){
        localStorage.setItem('eventSelect', JSON.stringify(datos))
    }else{
        localStorage.removeItem('eventSelect')
        localStorage.setItem('eventSelect', JSON.stringify(datos))
    }
}

export const postEvento = (state, datos) => {
    state.post = datos
}


export const postAtleta = (state, datos) => {
    state.atletaSeleccionado = datos
}

export const postCarreraSlug = (state, datos) =>{
    if(datos != undefined){
        state.datos = datos
        state.post = datos.post
        state.eventSelect = datos.evento
        state.camisas = datos.evento.camisas
        state.categorias = datos.evento.cat
    }
}


export const setEventosPorFecha = (state, data) => {
    state.eventosFecha = data
}


/*******************
 * 
 *  BLOGS
 * 
*/
export const setBlogs = (state, datos) => {
    state.blogs = datos
}

export const setBlogsPorFecha = (state, datos) => {
    state.blogsFecha = datos
} 

export const postBlogSlug = (state, datos) =>{

    state.datos = datos
    state.post = datos.post
    state.blogSelect = datos.post
}