<template>
  <Preloader :active="active" @act="act" />
  <HeaderPincipal />


  <router-view />


  <FooterPincipal />
</template>

<script>

import { defineAsyncComponent } from 'vue';
//import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'HomeComponent',
  components: {
    Preloader: defineAsyncComponent(() => import('@/components/preloader/index.vue')),
    HeaderPincipal: defineAsyncComponent(() => import('@/components/header.vue')),
    FooterPincipal: defineAsyncComponent(() => import('@/components/footer.vue')),
  },
  data() {
    return {
      active: true
    }
  },
  methods: {
    act(dato) {
      this.active = dato
    }
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0
}
</style>
