import emailApi from '@/api/emailApi.js'
import eventosApi from '@/api/eventosApi.js'


export const enviarCorreo = async ({ commit }, datos) => {
    const resp = await emailApi.post(`/api-correos`, datos)
    commit('correos', datos)
    return resp.data
}


export const setConfig = async ({commit}) => {
    if(localStorage.getItem('config')){
        if (tiempo() >= (1 * 60 * 60 * 1000)) { // un dia en ms
            localStorage.clear()
            const resp = await eventosApi.get('/config')
            commit('setConfig', resp.data)
            if(resp.data.error){ return resp.data }
            localStorage.setItem('fecha', new Date())
            localStorage.setItem('config', JSON.stringify(resp.data))
            return resp.data
        } else {
            commit('setConfig', JSON.parse(localStorage.getItem('config')))
            return localStorage.getItem('config')
        }
    }else{
        const resp = await eventosApi.get('/config')
        commit('setConfig', resp.data)
        if(resp.data.error){ return resp.data }
        localStorage.setItem('fecha', new Date())
        localStorage.setItem('config', JSON.stringify(resp.data))
        return resp.data
    }
}

export const setEventos = async ({commit}) => {
    if(localStorage.getItem('eventos')){
        if (tiempo() >= (1 * 60 * 60 * 1000)) { // un dia en ms
            localStorage.clear()
            const resp = await eventosApi.get('/eventos')
            commit('setEventos', resp.data)
            if(resp.data.error){ return resp.data }
            localStorage.setItem('fecha', new Date())
            localStorage.setItem('eventos', JSON.stringify(resp.data))
            return resp.data
        } else {
            commit('setEventos', JSON.parse(localStorage.getItem('eventos')))
            return localStorage.getItem('eventos')
        }
    }else{
        const resp = await eventosApi.get('/eventos')
        commit('setEventos', resp.data)
        if(resp.data.error){ return resp.data }
        localStorage.setItem('fecha', new Date())
        localStorage.setItem('eventos', JSON.stringify(resp.data))
        return resp.data
    }

    /*const resp = await eventosApi.get('/eventos')
    commit('setEventos', resp.data)
    return resp*/
}

export const setEventosActuales = async ({commit}) => {

    if(localStorage.getItem('eventosActuales')){
        if (tiempo() >= (1 * 60 * 60 * 1000)) { // un dia en ms
            localStorage.clear()
            const resp = await eventosApi.get('/eventos-actuales')
            commit('setEventosActuales', resp.data)
            if(resp.data.error){ return resp.data }
            localStorage.setItem('fecha', new Date())
            localStorage.setItem('eventosActuales', JSON.stringify(resp.data))
            return resp.data
        } else {
            commit('setEventosActuales', JSON.parse(localStorage.getItem('eventosActuales')))
            return localStorage.getItem('eventosActuales')
        }
    }else{
        const resp = await eventosApi.get('/eventos-actuales')
        commit('setEventosActuales', resp.data)
        if(resp.data.error){ return resp.data }
        localStorage.setItem('fecha', new Date())
        localStorage.setItem('eventosActuales', JSON.stringify(resp.data))
        return resp.data
    }

    /*const resp = await eventosApi.get('/eventos-actuales')
    commit('setEventosActuales', resp.data)
    return resp*/
}



/******************
 *    SERVICIOS
*******************/

export const setServicios = async ({commit}) => {

    if(localStorage.getItem('servicios')){
        if (tiempo() >= (1 * 60 * 60 * 1000)) { // un dia en ms
            localStorage.clear()
            const resp = await eventosApi.get('/servicios')
            commit('setServicios', resp.data)
            if(resp.data.error){ return resp.data }
            localStorage.setItem('fecha', new Date())
            localStorage.setItem('servicios', JSON.stringify(resp.data))
            return resp.data
        } else {
            commit('setServicios', JSON.parse(localStorage.getItem('servicios')))
            return localStorage.getItem('servicios')
        }
    }else{
        const resp = await eventosApi.get('/servicios')
        commit('setServicios', resp.data)
        if(resp.data.error){ return resp.data }
        localStorage.setItem('fecha', new Date())
        localStorage.setItem('servicios', JSON.stringify(resp.data))
        return resp.data
    }


    /*const resp = await eventosApi.get('/servicios')
    commit('setServicios', resp.data)
    return resp.data*/
}

/***********
 * funciones
 ***********/
/// CALCULAMOS TIEMPO

function tiempo(){
    const fecha = localStorage.getItem('fecha')
    const fecha2  = new Date()
    return fecha2 - fecha
}




/*
export const setAtletas = async ({ commit }, evento) => {
    const atletas = await generalApi.get(`/v1/atleta/${evento}/categoria`)
    const a = atletas.data.atletas
    const e = atletas.data.evento
    const c = atletas.data.categorias
    const es = atletas.data.estados
    const cl = atletas.data.clubs
    commit('mutAtletas', [a, e, c, es, cl])
}

export const setAtletasInscripciones = async ({ commit }, evento) => {
    const atletas = await generalApi.get(`/v1/atleta/${evento}/inscripciones`)
    const a = atletas.data.atletas
    const e = atletas.data.evento
    const c = atletas.data.categorias
    const es = atletas.data.estados
    //const cl = atletas.data.clubs
    commit('mutAtletas', [a, e, c, es])
}

export const selectAtleta = async ({ commit }, id) => {
    commit('selectAtleta', id)
}

export const atletaseleccionado = ({ commit }, atleta) => {
    commit('selectAtleta', atleta)
}

export const selectedEvento = ({ commit }, evento) => {
    commit('selectedEvento', evento)
}

export const selectedEventoInscripciones = ({ commit }, evento) => {
    commit('selectedEvento', evento)
}

export const seledEvento = ({ commit }, evento) => {
    commit('selectedEvento', evento)
}

export const updateAtleta = async ({ commit }, atleta) => {
    console.log('Atletaaaaaa');
    console.log(atleta);
    const resp = await generalApi.put(`/v1/atleta/${atleta[1]}`, atleta[0])
}
export const createAtleta = async ({ commit }, atleta) => {
    const resp = await generalApi.post(`/v1/atleta/register`, atleta)
    return resp.data.id
}
export const deleteAtleta = async ({ commit }, id) => {
    await generalApi.delete(`/v1/atleta/${id}`)
    commit('deleteAtleta', id)
    return id
}

export const confirmarPagoId = async ({ commit }, id) => {
    const data = await generalApi.post(`/v1/atleta/${id}/confirmar-pago`)
    commit('confirmarPagoId', id)
    return data
}

export const AsignarDorsal = async ({ commit }, dato) => {
    const data = await generalApi.post(`/v1/atleta/${dato.id}/asignar-dorsal`, dato)
    //commit('AsignarDorsal', id, dorsal)
    return data
}
/*
export const confirmarPagoId = async ({ commit }, dorsal, id) => {
    await generalApi.post(`/v1/atleta/${id}/confirmar-pago`)
    commit('confirmarPagoId', id)
    return id
}


export const vaciar = async ({ commit }, id) => {
    await generalApi.delete(`/v1/atleta/vaciar/${id}`)
    commit('vaciar')
    return id
}


export const setCats = ({ commit }, data) => {
    commit('setCategorias', data)
}
*/
