export const getTasa = (state) => {
    return state.tasa
}

export const getEventos = (state) => {
    return state.eventos
}

export const getEventoSeleccionado = (state) => {
    return state.eventSelect
}   

export const getCarreraSlug = (state) => {
    return state.post
}

export const getEventosPorFecha = (state) => {
    return state.eventosFecha
}


/*******************************
 * 
 *  Blog
 * 
 */

export const getBlogs = (state) => {
    return state.blogs
}

export const getBlogSeleccionado = (state) => {
    return state.blogSelect
}   

export const getBlogSlug = (state) => {
    return state.post
}

export const getBlogsPorFecha = (state) => {
    return state.blogsFecha
}








// export const eventosExclude = (state) => (id = '') => {
//     const evento = state.eventos.find(evento => evento.id === id)
//     if (!evento) return
//     return { ...evento }
// }