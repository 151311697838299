export default () => ({
    atletas: [],
    atletaSeleccionado: {},
    evento: {},
    eventos: [],
    categorias: [],
    camisas: [],
    correo: {},
    eventSelect: {},
    post: {},
    datos: {},
    eventosFecha: [],
    blogSelect: {},
    blogsFecha: []
})