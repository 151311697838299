import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/store'
import { createHead } from '@vueuse/head'
const head = createHead()

import '@/assets/css/bootstrap.min.css'
import '@/assets/css/fontawesome.min.css'
import '@/assets/css/magnific-popup.min.css'
import '@/assets/css/style.css'

createApp(App)
    .use(router)
    .use(store)
    .use(head)
    .mount('#app')
