import { createStore } from 'vuex'

import correo from './index'
import inscripcion from '@/modules/inscripcion/store/index.js'

const store = createStore({
    modules: {
        correo,
        inscripcion
    }
})

export default store