
import axios from 'axios'

const eventosApi = axios.create({
    baseURL: 'https://api360.360margaritaca.com/'
    // baseURL: 'http://localhost:8000/'
})

export default eventosApi


